import { component } from './mainPageApp.exports';
import { ComponentNoSentryWithAppSettingData } from './app.exports';
import { WidgetAppViewMode } from './components/WidgetApp/WidgetApp';

// file has to be js
export default {
  component,
  ComponentNoSentryWithAppSettingData: ComponentNoSentryWithAppSettingData(
    WidgetAppViewMode.PAGE,
  ),
};
