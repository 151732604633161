import { withSentryErrorBoundary } from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import { SENTRY_BOOKINGS_WIDGET_DSN } from './constants/bookings.const';
import {
  ComponentLeanCssNoSentryWithAppSettingData,
  ComponentNoSentryWithAppSettingData,
} from './app.exports';
import { WidgetAppViewMode } from './components/WidgetApp/WidgetApp';

const options = {
  dsn: SENTRY_BOOKINGS_WIDGET_DSN,
  config: {
    environment: 'ooi-main-page',
  },
};

export const component = withSentryErrorBoundary(
  ComponentNoSentryWithAppSettingData(WidgetAppViewMode.PAGE),
  options,
);

export const componentLeanCss = withSentryErrorBoundary(
  ComponentLeanCssNoSentryWithAppSettingData(WidgetAppViewMode.PAGE),
  options,
);
